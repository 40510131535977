import React from "react"

import Layout   from "../../components/share/upcc-layout"
import Header   from "../../components/Header/upcc"
import WallNews from "../../components/upcc/wall-news"
import News     from "../../components/upcc/news"
import Footer   from "../../components/share/footer"


export default () => {

  return (
    <Layout
      title="Noticias | UPC Cultural"
      metaTitle="Noticias | UPC Cultural"
      description="Visita y descubre experiencias, eventos y noticias de contenido artístico y cultural en el espacio de Noticias de UPC Cultural."
      subtitile="Visita y descubre experiencias, eventos y noticias de contenido artístico y cultural en el espacio de Noticias de UPC Cultural."
      metaDescripcin="Visita y descubre experiencias, eventos y noticias de contenido artístico y cultural en el espacio de Noticias de UPC Cultural."
      imgurl="//cultural.upc.edu.pe/static/UpcCultural_Share-a1f8e23f2b58627c09d19c7bfc611366.jpg"
      keywords="noticia, noticias, arte y cultura, UPC, UPC Cultural"
    >
      <Header/>

      <div class="upcc-container">

        <WallNews/>

        <News/>

      </div>

      <Footer/>
    </Layout>
  )
}
